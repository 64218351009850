const Menu = [
    {
      heading: "menu",
      route: "/stipend",
      pages: [
        {
          heading: "Stipend Eligibility Check ",
          route: "/stipend/stipend-eligibility",
          fontIcon: "fa-user",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Trainee Migration",
          route: "/stipend/trainee-migration",
          fontIcon: "fa-user-secret",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Trainee Selection",
          route: "/stipend/trainee-selection",
          fontIcon: "fa-user-secret",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
            heading: "Selected Trainee",
            route: "/stipend/selected-trainee",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
            heading: "Upload Disbursement Data",
            route: "/stipend/disbursement-data",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
            heading: "Disbursement Report",
            route: "/stipend/disbursement-report",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Stipend Payment Summary",
          route: "/stipend/stipend-payment-summary",
          fontIcon: "fa-user-secret",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Stipend Activity Report (By Association)",
          route: "/stipend/stipend-activity-report-association",
          fontIcon: "fa-user-secret",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Stipend Activity Report(By District)",
          route: "/stipend/stipend-activity-report-district",
          fontIcon: "fa-user-secret",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
      ],
    },
  ];
  
  export default Menu;
  